


























import { Component, Prop, mixins } from 'nuxt-property-decorator';
import MediaMixin from '~/mixins/MediaMixin';

@Component
export default class ImageFilling extends mixins(MediaMixin) {
  @Prop({ required: false, default: false }) data?: { allowCaption: boolean, inSwiper: boolean };
  $refs !: { container: HTMLUnknownElement };
  mounted () {
    const width = process.browser && this.$refs.container ? this.$refs.container.clientWidth : 500;
    const height = process.browser && this.$refs.container ? this.$refs.container.clientHeight : 0;
    this.imageurlSharp = this.generateImageUrl({ width, height, quality: 70, focal: this.focal, dpi: this.dpi });
  }
}
